// #############################
// FONTS
// #############################

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

$font-default:                      'Roboto', sans-serif;


// #############################
// GLOBAL WIDTHS
// #############################
$width-mobile-xs:                   400px;
$width-mobile-s:                    600px;
$width-mobile:                      800px;
$width-tablet:                      1024px;
$width-desktop:                     1280px;

// #############################
// COLORS
// #############################
$color-white:                       #ffffff;
$color-black:                       #000000;


$color-grey01:                      #f9f9f9;
$color-grey02:                      #e5e5e5;
$color-grey03:                      #d3d3d3;
$color-grey04:                      #c1c1c1;
$color-grey05:                      #bbbbbb;
$color-grey06:                      #999999;
$color-grey07:                      #777777;
$color-grey08:                      #555555;
$color-grey09:                      #333333;
$color-grey10:                      #111111;



$color-green01:                     #00ff6c;


$color-blue01:                      #36539F;

// #############################
// MIXINS
// #############################


// #############################
// ANIMATION TIME
// #############################
$time-hover:                        200ms;
$time-transition:                   500ms;


// #############################
// RESET
// #############################
html{ box-sizing: border-box; padding: 0; margin: 0; }
img{ border: none; }
*, *:before, *:after{ box-sizing: inherit; padding: 0; margin: 0; }