@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
html {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

img {
  border: none;
}

*, *:before, *:after {
  box-sizing: inherit;
  padding: 0;
  margin: 0;
}

/* base styles */
* {
  font-family: "Roboto", sans-serif;
  margin: 0;
  color: #111111;
}

.container {
  position: relative;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 20px;
}

.navbar {
  display: flex;
  align-items: center;
  margin: 0 auto;
  padding: 20px;
  border-bottom: 1px solid #d3d3d3;
}
.navbar h1 {
  color: #36539F;
}
.navbar .links {
  margin-left: auto;
}
.navbar a {
  margin-left: 16px;
  padding: 6px;
  text-decoration: none;
}
.navbar a::hover {
  color: #36539F;
}

.content {
  padding: 60px 0;
  background-color: #f9f9f9;
}
.content .pageTitle {
  margin-bottom: 10px;
}
.content p.title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #555555;
}
.content .buttonWrapper button {
  margin-right: 10px;
  padding: 5px 10px;
  color: #000000;
  border: 0;
  background-color: #00ff6c;
}

.blogList {
  margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid #d3d3d3;
}
.blogList:last-child {
  margin-bottom: 0;
  border: 0;
}
.blogList .btn {
  font-size: 10px;
  padding: 3px 5px;
  text-decoration: none;
  background-color: #00ff6c;
}

.blog,
.blog-details {
  position: relative;
  width: 100%;
  margin-bottom: 10px;
  padding: 20px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}
.blog .title,
.blog-details .title {
  font-size: 14px;
  font-weight: bold;
  width: 100%;
  margin-bottom: 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.blog .body,
.blog-details .body {
  margin-bottom: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  border-left: 1px solid #d3d3d3;
}
.blog .author,
.blog-details .author {
  font-size: 10px;
  width: 100%;
  text-align: left;
  opacity: 0.5;
}

.create .input-field {
  position: relative;
  width: 100%;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #d3d3d3;
}
.create .input-field label {
  font-size: 12px;
  position: relative;
  display: inline-block;
  width: 100%;
}
.create .input-field input,
.create .input-field textarea,
.create .input-field select {
  width: 200px;
  padding: 2px 5px;
  border: 1px solid #d3d3d3;
}/*# sourceMappingURL=index.css.map */