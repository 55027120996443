@import 'vars-mixins-reset';

/* base styles */
* {
    font-family: $font-default;
    margin: 0;
    color: $color-grey10;
}

.container {
    position: relative;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 20px;
}

.navbar {
    display: flex;
    align-items: center;
    margin: 0 auto;
    padding: 20px;
    border-bottom: 1px solid $color-grey03;

    h1 {
        color: $color-blue01;
    }

    .links {
        margin-left: auto;
    }

    a {
        margin-left: 16px;
        padding: 6px;
        text-decoration: none;

        &::hover {
            color: $color-blue01;
        }
    }
}

.content {
    padding: 60px 0;
    background-color: $color-grey01;

    .pageTitle {
        margin-bottom: 10px;
    }

    p.title {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 10px;
        color: $color-grey08;
    }

    .buttonWrapper {
        button {
            margin-right: 10px;
            padding: 5px 10px;
            color: $color-black;
            border: 0;
            background-color: $color-green01;
        }
    }
}

.blogList {
    margin-bottom: 40px;
    padding-bottom: 40px;
    border-bottom: 1px solid $color-grey03;

    &:last-child {
        margin-bottom: 0;
        border: 0;
    }

    .btn {
        font-size: 10px;
        padding: 3px 5px;
        text-decoration: none;
        background-color: $color-green01;
    }
}

.blog,
.blog-details {
    position: relative;
    width: 100%;
    margin-bottom: 10px;
    padding: 20px;
    border-radius: 10px;
    background-color: $color-white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);



    .title {
        font-size: 14px;
        font-weight: bold;
        width: 100%;
        margin-bottom: 10px;
        letter-spacing: 1px;
        text-transform: uppercase;
    }

    .body {
        margin-bottom: 10px;
        padding-bottom: 10px;
        padding-left: 10px;
        border-left: 1px solid $color-grey03;
    }

    .author {
        font-size: 10px;
        width: 100%;
        text-align: left;
        opacity: 0.5;
    }
}


.create {
    .input-field {
        position: relative;
        width: 100%;
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid $color-grey03;

        label {
            font-size: 12px;
            position: relative;
            display: inline-block;
            width: 100%;
        }

        input,
        textarea,
        select {
            width: 200px;
            padding: 2px 5px;
            border: 1px solid $color-grey03;
        }
    }
}